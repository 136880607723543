<template>
  <div>
    <section class="main services">
      <div class="hero parallax"></div>

      <h2>{{ currentRouteName }}</h2>

      <article>
        <div class="thumb"></div>
        <p class="intro">
          In today's technologically advanced world, Internet has become a necessity for people of all ages and from all areas of life. Almost every business and/or organization now has a presence in the Internet in the form of a website additionally embracing social media, mobility, cloud and persuasive computing.<br /><br />

          Though it is a great way to expand business opportunities and improve efficiency level, it is also risks and challenges businesses must face when they go online. Increasing cyber-attacks occur putting business valuable assets, network and data at unnecessary risks.<br /><br />

          GammaTeam Security specializes in providing cyber security services for all types of businesses and organizations. Our solutions are 100% customized and effective. We help minimize existing and potential risks, help protect business network, all critical information and data assets against emerging threats.
        </p>
        <div class="flex-container">
          <div class="services_subsection" v-for="(item, i) in items" :key="i">
            <h3>{{ item.label }}</h3>
            {{ item.text }}
          </div>
        </div>
      </article>

      <call-to-action :link_back=true></call-to-action>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CyberSecurity',
  data: () => ({
    items: [
      {
        label: 'Threat Management',
        text: 'Prevention of targeted and advanced threats aimed to steal business data, threat and security analytics, provision of actionable insight.'
      },
      {
        label: 'Vulnerability Management',
        text: 'Proactive testing of applications, scanning of network and database vulnerabilities'
      }
    ]
  })
}
</script>

<style scoped>
section.main article div.thumb {
  background-image: url("/images/services/cyber.jpg");
}
section.main article div.services_subsection {
  width: 48%;
}
</style>
